/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import Button from './button';
import './styled-components/helper.scss';
import { encode, axiosInstance } from '../../util/formUtils';

import {
  EmailFormBox,
  Input,
  Checkbox,
  TwoInputContainer,
  OneInputContainer,
  SmallInputStack,
  Label,
  CheckboxLabel,
  TextArea,
} from './styled-components/formStyledComponents';

const formName = 'emailQuestion';

const handleSubmit = (values, setSubmitting) => {
  axiosInstance
    .post(
      '/',
      encode({
        'form-name': formName,
        ...values,
      })
    )
    .then(() => {
      setSubmitting(false);
      navigate('/thanks', { state: { mode: 'email' } });
    })
    .catch(error => alert(error));
};

const configLookup = {
  contact: {
    body: 'Your Message',
    bodyPlaceholder: 'Enter your message',
    leftMargin: true,
    width: '100%',
  },
  commmissions: {
    body: 'Message',
    bodyPlaceholder:
      'Please share any initial thoughts about the painting you wish to commission. \nHint: desired size / color',
    leftMargin: false,
    width: '94%',
  },
};

const EmailForm = ({ pageEmbeddedIn }) => {
  return (
    <EmailFormBox
      leftMargin={configLookup[pageEmbeddedIn].leftMargin}
      width={configLookup[pageEmbeddedIn].width}
    >
      <Formik
        initialValues={{
          botField: '',
          email: '',
          phone: '',
          firstName: '',
          lastName: '',
          body: '',
          favoriteColor: '',
          joinMailingList: 'checked',
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required('Email Address Required'),
          phone: Yup.string(),
          favoriteColor: Yup.string(),
          body: Yup.string().required('Please enter your message'),
          firstName: Yup.string().required('First Name Required'),
          lastName: Yup.string().required('Last Name Required'),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          } = props;
          return (
            <form
              name={formName}
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="botField"
              onSubmit={handleSubmit}
            >
              <TwoInputContainer>
                <SmallInputStack>
                  {/*bot field*/}
                  <Label htmlFor="botField" style={{ display: 'none' }}>
                    Don’t fill this out if you are human:
                  </Label>
                  <Input
                    id="botField"
                    name="botField"
                    placeholder="Bot Field"
                    type="text"
                    value={values.botField}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.botField && touched.botField
                        ? 'text-input error'
                        : 'text-input'
                    }
                    style={{ display: 'none' }}
                  />
                  {/*first name*/}
                  <Label htmlFor="firstName" style={{ display: 'block' }}>
                    First
                  </Label>
                  <Input
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.firstName && touched.firstName
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.firstName && touched.firstName && (
                    <div className="input-feedback">{errors.firstName}</div>
                  )}
                </SmallInputStack>
                <SmallInputStack>
                  {/*last name*/}
                  <Label htmlFor="lastName" style={{ display: 'block' }}>
                    Last
                  </Label>
                  <Input
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.lastName && touched.lastName
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.lastName && touched.lastName && (
                    <div className="input-feedback">{errors.lastName}</div>
                  )}
                </SmallInputStack>
              </TwoInputContainer>
              <OneInputContainer>
                {/*email*/}
                <Label htmlFor="email" style={{ display: 'block' }}>
                  Email
                </Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </OneInputContainer>
              <OneInputContainer>
                {/*phone*/}
                <Label htmlFor="phone" style={{ display: 'block' }}>
                  Phone
                </Label>
                <Input
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number (optional)"
                  type="tel"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone && touched.phone
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.phone && touched.phone && (
                  <div className="input-feedback">{errors.phone}</div>
                )}
              </OneInputContainer>

              <OneInputContainer>
                {/*body*/}
                <Label htmlFor="body" style={{ display: 'block' }}>
                  {configLookup[pageEmbeddedIn].body}
                </Label>
                <TextArea
                  id="body"
                  name="body"
                  placeholder={configLookup[pageEmbeddedIn].bodyPlaceholder}
                  type="textarea"
                  rows="4"
                  columns="50"
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.body && touched.body
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.body && touched.body && (
                  <div className="input-feedback">{errors.body}</div>
                )}
              </OneInputContainer>

              <OneInputContainer>
                {/*favoriteColor*/}
                <Label htmlFor="favoriteColor" style={{ display: 'block' }}>
                  Favorite Color
                </Label>
                <TextArea
                  id="favoriteColor"
                  name="favoriteColor"
                  placeholder="What is your favorite color?"
                  type="text"
                  value={values.favoriteColor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.favoriteColor && touched.favoriteColor
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.favoriteColor && touched.favoriteColor && (
                  <div className="input-feedback">{errors.favoriteColor}</div>
                )}
              </OneInputContainer>

              <OneInputContainer className="container">
                {/*checkbox for join mailing list*/}
                <CheckboxLabel
                  htmlFor="joinMailingList"
                  style={{ display: 'block' }}
                >
                  Join our mailing list?
                  <Checkbox
                    id="joinMailingList"
                    name="joinMailingList"
                    type="checkbox"
                    value={values.joinMailingList}
                    checked={values.joinMailingList}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.joinMailingList && touched.joinMailingList
                        ? 'checkbox error'
                        : 'checkbox'
                    }
                  />
                  <span className="checkmark" />
                </CheckboxLabel>
                {errors.joinMailingList && touched.joinMailingList && (
                  <div className="input-feedback">{errors.joinMailingList}</div>
                )}
              </OneInputContainer>

              <TwoInputContainer>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  text="submit"
                  small
                />
                <Button
                  type="button"
                  clickHandler={e => {
                    e.preventDefault();
                    resetForm();
                  }}
                  disabled={!dirty || isSubmitting}
                  text="Reset"
                  small
                  gray
                />
              </TwoInputContainer>
            </form>
          );
        }}
      </Formik>
    </EmailFormBox>
  );
};

EmailForm.propTypes = {
  work: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  pageEmbeddedIn: PropTypes.string,
};

export default EmailForm;
